<template>
    <div class="page-top"><return /></div>
</template>
<script>
import Return from "./Return"
export default {
    name: "PageTop",
    components: {Return}
}
</script>
<style>
@media (max-width: 480px) {
    .page-top {
        display: flex;
        justify-content: end;
    }
}
/* desktop css */
@media (min-width: 480px) {
    .page-top {
        display: flex;
        justify-content: end;
    }
}
</style>