<template>
<div class="divider-container"><span class="divider"></span></div>
</template>
<script>
export default {
    name: "Divider"
}
</script>
<style>
  @keyframes expand {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }


@media (max-width: 480px) {
  .divider {

    height: 2px;
    animation-timing-function: ease-in-out;
    animation-name: expand;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    content: "";
    background: black;
  }
  .divider-container {
    display: flex;
    justify-content: center;
  }
}

/* desktop css */
@media (min-width: 480px) {

.divider {
    height: 2px;
    animation-timing-function: ease-in-out;
    animation-name: expand;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    content: "";
    background: black;
  }
  .divider-container {
    display: flex;
    justify-content: center;
  }
}
</style>