<template>
    <span class="diamond"></span>
</template>
<script>
export default {
    name: "Diamond"
}
</script>
<style>
.diamond {
  width: 0.3em;
  height: 0.3em;
  transform: rotate(45deg) skew(17.5deg, 17.5deg) translateX(0.35em);
  background: #fad326;
  border: solid #D5453A 3px;
}
</style>