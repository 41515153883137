<template>
  <div class="screen">
    <div class="message">
      <div class="message-content" style="white-space: pre-wrap" >
        {{ welcomeText[stage] }}
        <div v-if="!isMobile && stage == 0">For the best experience, please visit this site on
        mobile.</div>

      </div>
      <div class="diamond-container message-button" v-on:click="nextStage">
        <diamond /> {{ buttonText[stage] }}
      </div>

    </div>
  </div>
</template>
<script>
import Diamond from "../components/Diamond"
export default {
  name: "Welcome",
  components: {Diamond},
  data: () => ({
    stage: 0,
    buttonText: ["next","done"],
    welcomeText: [
"Welcome to the Tan Bone installation companion site.",
// `please shake your phone\n\nyou will hear protestors in Myanmar\n\nturn off
// silent and turn the volume up\n\nprotest together`
  `Shake your phone to hear protest recordings\nNo sound? Turn off silent and check the volume.`

    ]
  }),
  methods: {
    nextStage: function () {
      if (this.stage == 0) {
        this.stage++;
        this.$emit('start')
      } else {
        this.$router.push("/");
      }
    },
  },
  computed: {
    isMobile: function(){
      return window.innerWidth < 480 
    }
  }
};
</script>
<style>

.message-button {
  margin-top: 10vh;
  padding: 5px;
  float: right;
  display: flex;
  align-self: center;
  cursor: pointer;
}
svg {
  align-self: center;
  padding: 0px 5px;
}
.message {
  font-family: AventaBold, sans-serif;
  width: 80vw;
  margin: auto;
  margin-top: 40vh;
}

.message-content {
  opacity: 1;
  text-align: center;
}
.screen {
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
@media (max-width: 480px) {
}

/* desktop css */
@media (min-width: 480px) {
}
</style>