import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Welcome from "../views/Welcome.vue";
// import Pots from "../views/Pots.vue";
// import SinglePot from "../views/SinglePot.vue"
import MultiPots from "../views/MultiPots.vue"
import About from "../views/About.vue";
import Acknowledgements from "../views/Acknowledgements.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
  },
  // { path: "/pots", name: "Pots", component: Pots },
  { path: "/pots", name: "Pots", component: MultiPots },
  // { path: "/pots", name: "Pots", component: SinglePot },
  { path: "/about", name: "About", component: About },
  { path: "/acknowledgements", name: "Acknowledgements", component: Acknowledgements },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
