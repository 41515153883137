<template>
  <div id="graphics-container"></div>
</template>

<script>
import * as p5 from "p5";
export default {
  name: "Graphics",
  data: () => ({}),
  mounted: function () {
    let breakpoint = 480;
    let mobile = window.innerWidth < breakpoint ? true : false;
    let BEIGE = "#E3D5B8";
    let RED = "#D5453A";
    let YELLOW = "#FAD326";
    let OLIVE = "#918034";
    let BLACK = "#000";
    let baseRowColors = [YELLOW, OLIVE, BLACK];
    let secondaryRowColors = [RED, YELLOW, RED];
    let currentPage = "";
    let scale = 1;
    let dScale = 1;
    let xOff = 0;
    let dxOff = 0;
    let yOff = 0;
    let dyOff = 0;
    let dAlpha = 0;
    let alpha = 0;
    let rsize = mobile ? 0.14 : 0.145;

    let sketch = function (p) {
      let rhombi = [];
      p.setup = function () {
        let cnv = p.createCanvas(p.windowWidth, p.windowHeight);
        cnv.parent("graphics-container");
        for (let row = -2; row < 3; row++) {
          for (let col = -1; col < 2; col++) {
            if (
              (Math.abs(row) == 2 && col != 0) ||
              (Math.abs(row) == 1 && col < 0)
            ) {
              continue;
            }
            // let xalign = mobile ? 0.0 : -0.35;
            let xalign = 0.;
            let rw = p.windowHeight * rsize;
            let rh = rw * 1.9;
            let xo = rw * 0.5 * (Math.abs(row) % 2);
            let x = p.width * xalign + col * rw - xo;
            let y = row * rh * 0.5;
            let rhombus = new Rhombus(x, y, rw, rh);
            rhombus.color = baseRowColors[Math.abs(row)];
            rhombus.secondaryColor = secondaryRowColors[Math.abs(row)];
            rhombus.init();
            rhombi.push(rhombus);
          }
        }
      };
      p.draw = function () {
        scale += (dScale - scale) * 0.05;
        xOff += (dxOff - xOff) * 0.05;
        yOff += (dyOff - yOff) * 0.05;
        alpha += (dAlpha - alpha) * 0.05;
        currentPage = window.location.pathname.substring(1);
        switch (currentPage) {
          case "":
            dScale = 1;
            dyOff = 0;
            dxOff = 0;
            dAlpha= 0;
            break;
          case "pots":
            dyOff = 0;
            dxOff = 0;
            dScale = mobile ? 6 : 12;
            dAlpha = 190;
            break;
          case "about":
            dScale = mobile ? 6 : 12;
            dxOff = -p.windowHeight * rsize * (mobile ? 3 : 6);
            dyOff = -p.windowHeight * rsize * 1.9 * (mobile ? 3 : 6);
            dAlpha = 190;
            break;
          case "acknowledgements":
            dScale = mobile ? 6 : 12;
            dxOff = p.windowHeight * rsize * (mobile ? 3 : 6);
            dyOff = -p.windowHeight * rsize * 1.9 * (mobile ? 3 : 6);
            dAlpha = 190;
            break;
          default:
            break;
        }
        p.background(BEIGE);
        p.noStroke();
        p.translate(p.width * 0.5 + xOff, p.height * 0.5 + yOff);
        p.scale(scale);
        rhombi.forEach((rhombus) => {
          rhombus.update();
          rhombus.draw();
        });
        p.fill(255,255,255,alpha);
        p.rect(-p.width*0.5,-p.height*0.5,p.width*2,p.height*2)
      };
      p.windowResized = function () {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
      };

      class Rhombus {
        color;
        secondaryColor;
        layers = [];
        verts = [];
        numLayers = 6;
        period = 600;
        constructor(x, y, width, height) {
          this.x = x;
          this.y = y;
          this.width = width;
          this.height = height;
          this.color = p.color(255, 255, 255);
          this.secondaryColor = p.color(255, 255, 255);
        }
        init() {
          for (let i = 0; i < this.numLayers; i++) {
            let offset = (i / this.numLayers) * this.period;
            let size = (offset % this.period) / this.period;
            let col = i % 2 == 0 ? this.color : this.secondaryColor;
            this.layers.push({ offset, size, col });
          }
          for (let i = 0; i < 4; i++) {
            let theta = p.PI * 0.5 * i;
            let r = i % 2 == 0 ? this.width * 0.5 : this.height * 0.5;
            let X = p.cos(theta) * r + this.x;
            let Y = p.sin(theta) * r + this.y;
            this.verts.push(p.createVector(X, Y));
          }
        }
        drawRhombus(x, y, w, h) {
          p.beginShape();
          for (let i = 0; i < 4; i++) {
            let theta = p.PI * 0.5 * i;
            let r = i % 2 == 0 ? w * 0.5 : h * 0.5;
            p.vertex(p.cos(theta) * r + x, p.sin(theta) * r + y);
          }
          p.endShape(p.CLOSE);
        }

        update() {
          this.layers.sort(function (a, b) {
            return b.size - a.size;
          });
          this.layers.forEach((layer) => {
            layer.size =
              ((layer.offset + p.frameCount) % this.period) / this.period;
          });
        }
        draw() {
          let phase = Math.floor(p.frameCount / (this.period / this.numLayers));
          let c = phase % 2 == 0 ? this.color : this.secondaryColor;
          p.fill(c);
          this.drawRhombus(this.x, this.y, this.width, this.height);
          this.layers.forEach((layer) => {
            p.fill(layer.col);
            this.drawRhombus(
              this.x,
              this.y,
              layer.size * this.width,
              layer.size * this.height
            );
          });

          p.fill(this.secondaryColor);
          this.drawRhombus(
            this.x,
            this.y,
            this.width * 0.62,
            this.height * 0.62
          );
        }
        contains(x, y) {
          let c = false;
          for (let i = 0; i < 2; i++) {
            let t0 = this.verts[0 + i * 2];
            let t1 = this.verts[1 + i * 2];
            let t2 = this.verts[(2 + i * 2) % 4];
            let v0x = t2.x - t0.x;
            let v0y = t2.y - t0.y;
            let v1x = t1.x - t0.x;
            let v1y = t1.y - t0.y;
            let v2x = x - window.innerWidth * 0.5 - t0.x;
            let v2y = y - window.innerHeight * 0.5 - t0.y;
            let dot00 = v0x * v0x + v0y * v0y;
            let dot01 = v0x * v1x + v0y * v1y;
            let dot02 = v0x * v2x + v0y * v2y;
            let dot11 = v1x * v1x + v1y * v1y;
            let dot12 = v1x * v2x + v1y * v2y;
            let b = dot00 * dot11 - dot01 * dot01;
            let inv = b === 0 ? 0 : 1 / b;
            let u = (dot11 * dot02 - dot01 * dot12) * inv;
            let v = (dot00 * dot12 - dot01 * dot02) * inv;
            if (u >= 0 && v >= 0 && u + v < 1) {
              c = true;
              break;
            }
          }
          return c;
        }
      }
    };
    new p5(sketch);
  },
};
</script>

<style>
.p5Canvas {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}
</style>
