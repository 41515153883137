<script>
import { Howler, Howl } from "howler";

export default {
  name: "Sound",
  data: () => ({
    playing: false,
    sources: [
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/bells-1.mp3?v=1653393199154",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/box-2.mp3?v=1653393204609",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/chant-3.mp3?v=1653393206209",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/box-1.mp3?v=1653393206632",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/chant-2.mp3?v=1653393206783",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/chant-1.mp3?v=1653393206974",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/chant-4.mp3?v=1653393207819",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/protest-2.mp3?v=1653393208306",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/sing-1.mp3?v=1653393208567",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/box-3.mp3?v=1653393209493",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/chant-6.mp3?v=1653393209521",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/chant-5.mp3?v=1653393209565",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/protest-1.mp3?v=1653393209816",
      "https://cdn.glitch.global/9d43f51d-0208-4b7f-bfed-3cad6b7ec8a2/drum-1.mp3?v=1653393211744"
      // "https://cdn.glitch.com/1272abfd-c3bb-417e-af76-59727da0cce9%2Fmyanmarprotest.mp3?v=1612514585279",
      // "https://cdn.glitch.com/1272abfd-c3bb-417e-af76-59727da0cce9%2Fprotest3.mp3?v=1612621073322",
      // "https://cdn.glitch.com/1272abfd-c3bb-417e-af76-59727da0cce9%2Fprotest2.mp3?v=1612621072530",
      // "https://cdn.glitch.me/1272abfd-c3bb-417e-af76-59727da0cce9%2Fprotest4.mp3",
      
    ],
    sounds: [],
    x: 0,
    y: 0,
    z: 0,
    dist: 0,
    ease: 0.05,
    volEase: 0.07,
    thresh: 200,
    vol: 0,
    seed: 0,
    realVol: 0,
    iosVersion: 0,
  }),

  methods: {
    startApp: function () {
      this.seed = Math.floor(Math.random() * this.sources.length);
      if (this.isIOS && this.iosVersion > 12) {
        DeviceMotionEvent.requestPermission();
      }
      this.sounds[this.seed].play();
    },
    handleDeviceMotion: function (e) {
      this.x = e.acceleration.x;
      this.y = e.acceleration.y;
      this.z = e.acceleration.z;
      //use acceleration to calculate the distance moved by phone
      var d = this.dist3D(this.x, this.y, this.z);
      this.dist += d - this.dist * this.ease;
      //vol is an on-off switch
      this.vol = this.dist > this.thresh ? 1 : 0;
      this.realVol += this.vol - this.realVol * this.volEase;
      this.realVol = this.value_limit(this.realVol, 0, 1);
      Howler.volume(this.realVol);
    },
    dist3D: function (x, y, z) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    },
    value_limit: function (val, min, max) {
      return val < min ? min : val > max ? max : val;
    },
  },
  computed: {
    numSources: function () {
      return this.sources.length;
    },
    seed: function () {
      return Math.floor(Math.random() * this.numSources);
    },
    isIOS: function () {
      return (
        (/iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === "MacIntel" &&
            navigator.maxTouchPoints > 1)) &&
        !window.MSStream
      );
    },
  },
  mounted: function () {
    for (var i in this.sources) {
      this.sounds.push(new Howl({ src: [this.sources[i]], loop: true }));
      Howler.volume(0);
    }
    var agent = window.navigator.userAgent;
    var start = agent.indexOf("OS");
    if (
      (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
      start > -1
    ) {
      this.iosVersion = window.Number(
        agent.substr(start + 3, 3).replace("_", ".")
      );
    }
    window.addEventListener("devicemotion", this.handleDeviceMotion);
  },
};
</script>