<template>
  <div class="content-page">
    <page-top />
    <divider />
    <div><h1 class="page-title">acknowledgements</h1></div>
    <div v-for="(person, index) in credits" :key="index">
      <div class="credit">
        <div class="credit-role">{{ person.role }}</div>
        <div class="credit-name">{{ person.name }}</div>
      </div>
    </div>
    <div id="special-thanks">Special thanks to the families of the martyrs.</div>
  </div>
</template>
<script>
import PageTop from "../components/PageTop.vue";
import Divider from "../components/Divider.vue";

export default {
  name: "Acknowledgements",
  components: { PageTop, Divider },
  data: () => ({
    credits: [
      { name: "Kam Seng Aung", role: "Pot collection, Field Recordings" },
      { name: "Ja San Pan", role: "Field Recordings" },
      { name: "Joshua Weitzel", role: "Sound Installation" },
      { name: "Pa Pa Wadi", role: "Text, Photos" },
      { name: "Sam Tsao", role: "Web App" },
    ],
  }),
};
</script>
<style>
.credit {
    text-align: center;
    margin: 3vh 0;
}
.credit-name {
    font-family: AventaMedium;
    font-size: 1.2em;
}
#special-thanks {
  text-align: center;
}
</style>
