<template>
  <nav>
    <ul>
      <li>
        <router-link to="/pots">the pots</router-link >
        <diamond />
      </li>
      <li><router-link to="/about">the installation</router-link>
        <diamond />
      </li>

      <li>
        <router-link to="/acknowledgements">acknowledgements</router-link>
        <diamond />
      </li>
    </ul>
  </nav>
</template>
<script>
import Diamond from "./Diamond.vue"
export default {
  name: "Navigation",
  components: {Diamond}
};
</script>
<style>
/* mobile css */
a {
  color: black;
  font-family: AventaMedium;
  padding: 0vh 2vw;
}
ul > li {
  font-size: 1em;
  text-align: right;
  list-style: none;
  padding: 1.5vh 0;
  display: flex;
  justify-content: flex-end;
}


.diamond-container {
    display: flex;
    gap: 10px;
}

@media (max-width: 480px) {
  nav {
    position: absolute;
    bottom: 2vh;
    right: 5vw;
  }
  a {
    padding: 0vh 2vw;
  }
}

/* desktop css */
@media (min-width: 480px) {
  nav {
    position: absolute;
    bottom: 10vh;
    right: 25vw;
  }
  a {
    padding: 0vh 0.5vw;
  }
}
</style>