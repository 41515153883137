<template>
  <div class="about content-page">
    <page-top />
    <divider />
    <div><h1 class="page-title">about the installation</h1></div>
    <p>
      Tan Bone (သံဗုံး), meaning 'Sound Pot' in Myanmar, is a collaboration
      between sound artist Joshua Weitzel and artist/musician Kam Seng Aung.
    </p>
    <p>
      On the 1st of February, Aung San Suu Kyi and her party was taken over by
      the military by force in the early hours of the morning. Due to the
      current Covid situation, most did not go out to show their anger, instead,
      as per our Burmese tradition to cast away evil military and to show their
      anger, some folks have been banging pots, pans, metal trays, and any
      material that they could hit or to produce loud noise.
    </p>
    <p>
      A few days later, a small group, led by young folks, started the lighting
      of candles and banging of pots and pans at 8pm at nights and that has
      spreader across the cities and country. This is where the non violent
      drumming movement was established and started to show their disapproval for
      the military took over.
    </p>
    <p>
      The practice of hitting pots and pans has been around since the time of
      the Burmese kings. Then, it was to cast out the robbers/bandits and also
      to warn other neighbouring villages. Moreover, the loud noise created by
      metal pot was also to drive away evil spirits and large forest animals.
    </p>
    <p>
      This practice can also be seen in neighbouring countries such as India and
      Thailand. In India, it is to drive away insects and crickets which destroy
      crops.
    </p>
    <p>
      As for Thailand, it is done to drive away unwanted or unwelcome
      people/person.
    </p>
    <p>
      Based on this simple fact, to cast out negative energy/person, it is
      believed that most people are using this method to observe civil
      disobedience nonviolently due to the extreme atrocities caused by unwanted
      group of people(military).
    </p>
    <p>
      As a result, the reflection of the citizens' heart beats will translate
      into the sound of pots and pans every night at 8pm in Burma.
    </p>
    <p>
      This site was created as a companion web app to the Tan Bone installation
      as an act of solidarity.
    </p>
    <divider/>
    <page-top/>
  </div>
</template>

<script>
import PageTop from "../components/PageTop";
import Divider from "../components/Divider";
export default {
  name: "about",
  components: { PageTop, Divider },
};
</script>
<style>
</style>