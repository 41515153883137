<template>
  <div id="app">
    <transition name="fade">
    <router-view v-on:start="start" />
    </transition>
    <graphics />
    <sound ref="sound"/>
  </div>
</template>

<script>
import Graphics from '@/components/Graphics'
import Sound from "@/components/Sound"

export default {
  components: { Graphics, Sound },
  name: "App",
  computed: {
    isMobile: function () {
      let res = window.innerWidth < 480 ? true : false;
      return res;
    },
  },
  mounted: function(){
        this.$router.push("/welcome");
  },
  methods: {
    start: function(){
      this.$refs.sound.startApp()
    }
  }
};
</script>


<style>
@font-face {
  font-family: "AventaBold";
  src: local("AventaBold"),
  url(./assets/fonts/Aventa-Bold.ttf) format("truetype")
}
@font-face {
  font-family: "AventaThin";
  src: local("AventaThin"),
  url(./assets/fonts/Aventa-Thin.ttf) format("truetype")
}
@font-face {
  font-family: "AventaMedium";
  src: local("AventaMedium"),
  url(./assets/fonts/Aventa-Medium.ttf) format("truetype")
}
@font-face {
  font-family: "AventaLight";
  src: local("AventaLight"),
  url(./assets/fonts/Aventa-Light.ttf) format("truetype")
}
*,h1 {
  font-family: "AventaLight";
  font-weight: 100;
}
.fade-enter-to {
  opacity: 1.0;
}
.fade-enter-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0.0;
}
/* mobile css */
@media (max-width: 480px) {
  h1 {
    font-size: 1.5em;
    text-align: center;
  }
  .page-title{
    margin: 5vh 0vw;
  }
}

/* desktop css */
@media (min-width: 480px) {
  h1 {
    font-size: 3em;
    text-align: center;
    font-family: AventaMedium;
  }
  
}
</style>
