<template>
  <h1 class="site-title">tan<br />bone</h1>
</template>
<script>
export default {
  name: "SiteTitle",
};
</script>
<style>
.site-title {
  font-family: AventaBold, Arial, sans-serif;
  font-weight: bold;
  color: white;
  text-shadow: #00000027 0px 4px 24px;
}
/* mobile css */
@media (max-width: 480px) {
  .site-title {
    font-size: 5em;
    line-height: 1.25em;
    text-align: center;
  }
}

/* desktop css */
@media (min-width: 480px) {
.site-title {
  /* position: absolute; */
    font-size: 7em;
    line-height: 1.25em;
    margin-left: auto;
    text-align: center;
    margin-top: 10vh;
  }
}
</style>