<template>
  <div class="content-page">
    <page-top />
    <divider />
    <div>
      <h1 class="page-title">the pots</h1>
    </div>
    <p>
      These five young men were among over 850 women and men that have been
      murdered by the military regime since it took power on the 1st of
      February, 2021.
    </p>
    <div v-for="(martyr, index) in martyrs" :key="index">
      <h3>
        {{ index + 1 }}. {{ martyr.name }} ({{ martyr.age }}), died on the
        {{ martyr.date }} in {{ martyr.location }}
      </h3>
      <img v-if="martyr.image" :src="require(`../assets/photos/${martyr.image}`)" />
      <p v-for="paragraph in martyr.story" :key="paragraph">
        {{ paragraph }}
      </p>
    </div>
    <divider />
    <page-top/>
  </div>
</template>
<script>
import PageTop from "../components/PageTop.vue";
import Divider from "../components/Divider";
export default {
  components: { PageTop, Divider },
  data: () => ({
    martyrs: [
      {
        name: "Ko Na Pwa",
        age: "32",
        date: "8th of February",
        location: "Mandalay",
        image: "1.jpg",
        story: [
          "On the 8th of February, a week after the military junta overthrew the civilian government and imprisoned its leaders, Ko Na Pwa was hit twice by an unmarked car while returning from a protest in Mandalay. He died five minutes after being hit. His family and community believe that the assassins were thugs hired by the military.",
          "Ko Na Pwa lived in a poor part of the city, where he worked as a butcher for goat meat. A devotee of Aung San Su Kyi, he carried her image tattooed on his chest.",
          "He protested by day and, by night, volunteered for guard duty to protect his neighbourhood.",
          "Ko Na Pwa was Muslim and the middle son of three brothers. His parents had passed away when the children were still young.",
          "Thousands attended his funeral in Mandalay.",
        ],
      },
      {
        name: "Nyi Nyi Aung Htet Naing",
        age: "23",
        date: "28th of February, 2021",
        location: "Yangon",
        image: null,
        story: [
            "Nyi Nyi Aung Htet Naing was shot dead on 28 February while participating in a peaceful protest outside No. 5 Basic Education High School in Kamayut Township, Yangon. His twin brother shares Nyi Nyi Aung Htet Naing's story:",
            "\"We are twins. We were always very close and very attached to each other. We were best friends. We wore the same clothes until we 15. We shared the same bed. Later, up until his death, we shared our clothes. We shared one room, we studied together. When we joined university, we realized we had different interests: he was interested in engineering, I in architecture. Sometimes we argued, but we knew that in difficult moments, we would always be there for each other. He was very determined, he couldn't stand injustice. And he loved his friends. He always helped everyone. One day, after he had a fight with a close friend, he felt so bad that he took the day of work, went to buy some snacks and spent the day with that friend. He was really caring.",
            "The elections before the coup - that was the first time we could vote! We were so excited. We believed in democracy. The coup destroyed our hopes and we went to protest together. Every morning, it took us two to three hours to get to the city. We took leave from work and went protesting every day. Nyi Nyi was so determined. On 28 February, we arrived at eight in the morning at the Hledan junction. There were thousands of us and the military surrounded us from all sides. Everyone was wearing white so that you could see us on the satellite images. At nine, they started throwing gas and sound bombs. I told Nyi Nyi not to go far away. I was afraid we would lose each other. People ran and hid in the staircases of the apartment buildings, and he said: \"We didn't come all this way to hide!\" That was the last time we saw each other. Then we were separated. I tried calling him, but he wasn't picking up the phone. My friends heard it first. They tried to protect me, they took my phone away so I wouldn't see the pictures. They told me he had been shot in the hip, that he would be fine. In truth, he was shot in the stomach. The bullet went through his stomach into his back. When he was shot, he called mother and told her: \"I was shot. Mother, I cannot come back home\". And she wasn't understanding what he was saying.",
            "We were always together. I constantly think of him. Sometimes, I still cannot believe that he is gone, and I tell myself that he just went on a trip, that he will return soon - and then I remember. I want to see him, talk to him, I want to share my thoughts with him as we always have. I keep thinking that any minute we will meet again.",
            "After Nyi Nyi died, I had to hide. When they came for me and didn't find me, they took my mother. I will only be able to go home when the revolution is over.\""
        ]
      },
      // {
      //   name: "Nay Myo Aung",
      //   age: "16",
      //   date: "3rd of March",
      //   location: "Mandalay",
      //   image: "2.jpg",
      //   story: [
      //     "Nay Myo Aung lived in a poor neighbourhood of Mandalay together with his grandmother, taking on odd jobs to support the two of them. He was 16 years old and the youngest of three siblings. His parents had passed away when he was one year old.",
      //     "On the 3rd of March, during a protest in Mandalay, security services shot him fatally in the chest.",
      //   ],
      // },
      {
        name: "Ye Thu Aung",
        age: "age unknown",
        date: "19th of March",
        image: "3.jpg",
        location: "Aung Ban, Shan State",
        story: [
          "The exact circumstances of Ye Thu Aung’s death are unclear and conflicting versions circulate on social media. He was celebrated as a hero when stories emerged that he had killed four soldiers and one police man with a sword before he was fatally shot in the head during a crackdown on a protest on the 19th of March in Aungban, Shan State. By then, the security services had killed 235 protestors. Amidst grief and anger over the loss of so many lives, calls for the right to self-defence grew louder among the resistance.",
          'Ye Thu Aung lived with his parents in a village in Shan State. Before the coup, he volunteered at a covid quarantine community center. During the protests, he was respected for taking the frontline defence and protecting others. He also staged a street role play, a satire on how the military had murdered justice. "If you fear those who have weapons, then you will live your entire life under oppression", his friends remember him saying.',
        ],
      },
      {
        name: "Shein Naung Naung",
        age: "19",
        date: "1st of April",
        location: "Yangon",
        image: "4.jpg",
        story: [
          "Shein Naun Naung lived with his widowed mother, his sister and younger brother in Yangon. He was a final year highschool student when the global pandemic closed down schools. Then the junta took power. Shein Naung Naung and his friends decided to fight for their future. Every day, they went out to protest against the dictatorship.",
          "On the 23rd of March, security services shot Shein Naung Naung in the spine during a protest crackdown. He died nine days later, hidden in a gynaecological ward, as the hospital’s emergency rooms were regularly raided by security services in search of injured protestors.",
          "After a full day of protests, every night at 8pm., Shein Naung Naung joined millions of Myanmar women and men banging pots and pans to express their opposition to the dictatorship. Because their neighbourhood has many informers, he carefully closed the curtains before beginning to bang.",
        ],
      },
      {
        name: "Shein Htet Aung",
        age: "27",
        date: "27th of March",
        location: "Yangon",
        image: "5.jpg",
        story: [
          'Shein Htet Aung had just received his engineering degree and attended online classes during the Covid 19 lockdown. He studied English, and about pluralism and democracy. When the military took power, he first protested near his village, then travelled to Yangon to join the large protests in the city. Every day, he went out protesting with his cousins. On the few days that they were too exhausted to go, he felt guilty and discussed ways to oppose the dictatorship. "He was always sharing information about what was happening on social media. He really wanted to be a citizen journalist and he had many followers", explained his cousin. He believed that it was his "citizen duty to fight the dictatorship".',
          "As more and more young people died in the streets, his mother begged him to return home. He planned to return on March 25, but then decided to leave only after joining protests on March 27, Armed Forces Day, which the opposition had renamed Revolution Day. The cousins had their favourite Shan noodles for breakfast and went to protest in different neighbourhoods. His cousin learned later at night, after searching social media for images of the day’s 114 dead, that Shein Htet Aung had been killed. When the shooting started, he and his friends found shelter on the staircase of an apartment building. Soldiers then threw a grenade at them. Shein Htet Aung caught it and as he tried to throw it back, it exploded inside his hand and killed him. His friends were detained and charged with possession of explosives among other charges.",
        ],
      },
      // {
      //   name: "Nyi Nyi Aung Htet Naing",
      //   age: "23",
      //   date: "28 February 2021",
      //   location: "Yangon",
      //   image: null,
      //   story: [
      //       "Nyi Nyi Aung Htet Naing was shot dead on 28 February while participating in a peaceful protest outside No. 5 Basic Education High School in Kamayut Township, Yangon. His twin brother shares Nyi Nyi Aung Htet Naing's story:",
      //       "\"We are twins. We were always very close and very attached to each other. We were best friends. We wore the same clothes until we 15. We shared the same bed. Later, up until his death, we shared our clothes. We shared one room, we studied together. When we joined university, we realized we had different interests: he was interested in engineering, I in architecture. Sometimes we argued, but we knew that in difficult moments, we would always be there for each other. He was very determined, he couldn't stand injustice. And he loved his friends. He always helped everyone. One day, after he had a fight with a close friend, he felt so bad that he took the day of work, went to buy some snacks and spent the day with that friend. He was really caring.",
      //       "The elections before the coup - that was the first time we could vote! We were so excited. We believed in democracy. The coup destroyed our hopes and we went to protest together. Every morning, it took us two to three hours to get to the city. We took leave from work and went protesting every day. Nyi Nyi was so determined. On 28 February, we arrived at eight in the morning at the Hledan junction. There were thousands of us and the military surrounded us from all sides. Everyone was wearing white so that you could see us on the satellite images. At nine, they started throwing gas and sound bombs. I told Nyi Nyi not to go far away. I was afraid we would lose each other. People ran and hid in the staircases of the apartment buildings, and he said: \"We didn't come all this way to hide!\" That was the last time we saw each other. Then we were separated. I tried calling him, but he wasn't picking up the phone. My friends heard it first. They tried to protect me, they took my phone away so I wouldn't see the pictures. They told me he had been shot in the hip, that he would be fine. In truth, he was shot in the stomach. The bullet went through his stomach into his back. When he was shot, he called mother and told her: \"I was shot. Mother, I cannot come back home\". And she wasn't understanding what he was saying.",
      //       "We were always together. I constantly think of him. Sometimes, I still cannot believe that he is gone, and I tell myself that he just went on a trip, that he will return soon - and then I remember. I want to see him, talk to him, I want to share my thoughts with him as we always have. I keep thinking that any minute we will meet again.",
      //       "After Nyi Nyi died, I had to hide. When they came for me and didn't find me, they took my mother. I will only be able to go home when the revolution is over.\""
      //   ]
      // }
    ],
  }),
};
</script>
<style>
@keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
  img {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
@media (max-width: 480px) {

  p{
      line-height: 1.25em;
  }
  .page-top {
    display: flex;
    justify-content: end;
  }
  .divider {
    height: 2px;
    animation-timing-function: ease-in-out;
    animation-name: expand;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    content: "";
    background: black;
  }
  .divider-container {
    display: flex;
    justify-content: center;
  }
}

/* desktop css */
@media (min-width: 480px) {
  .content-page {
    width: 39vw;
    margin: auto;
  }
  
}
</style>