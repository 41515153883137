<template>
  <div class="home">
    <site-title></site-title>
    <span class="author-liner">by kam seng aung, joshua weitzel and sam
    tsao</span>
    <navigation />
  </div>
</template>

<script>
// @ is an alias to /src
import SiteTitle from "../components/SiteTitle"
import Navigation from "../components/Navigation"

export default {
  name: 'Home',
  components: { SiteTitle,Navigation }
}
</script>
<style>

/* mobile css */
@media (max-width: 480px) {
  .author-liner {
  font-family: AventaThin;
  writing-mode: sideways-lr;
  position: absolute;
  font-size: 0.9em;
  top: 2vh;
  right: 3vw;
}
}

/* desktop css */
@media (min-width: 480px) {
  .author-liner {
    writing-mode: sideways-lr;
    position: absolute;
    font-size: 1.5em;
    bottom: 5vh;
    right: 1vw;
  }
}
</style>
