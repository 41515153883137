<template>
    <router-link to="/" :class="`back-prompt`">back<span class="diamond"></span></router-link>
</template>
<script>
export default {
    name: "Return"
}
</script>
<style>
/* <a> styling inside navigation */
@media (max-width: 480px) {
    .back-prompt{
        text-align: right;
        margin: 2vh 0vw;
        display: flex;
    }
}

/* desktop css */
@media (min-width: 480px) {
    .back-prompt{
        text-align: right;
        margin: 2vh 0vw;
        display: flex;
        /* justify-content: flex-end; */
    }
}

</style>